/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React, { Component, Fragment } from "react";
import { Map, List, Record } from "immutable";
import axios from "axios";
import { Table, Container, Row, InputGroupText, InputGroup, Col, Button, Form, FormText, FormGroup, Label, Input } from "reactstrap";
import HeaderComponent from "../header/HeaderComponent";

import * as SPLAT from "../../scripts/gsplat/index.js";

// import * as GaussianSplats3D from "../../scripts/three/gaussian-splats-3d.module.js";


function statusChange(type) {
	const { data } = this.state;
	this.setState({ data: data.set("currentSlamState", type) });
}

class PlyToSplat extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: Map({
				totalByte: 0,
				byteUploaded: 0,
				prompt: "",
				result_ext: "usdz",
				files: [],
				engine_type: "model_converter-dev",
				email: "",
				totalFileNum: 0,
				currentFileNum: 0,
				decimate_ratio: 0.5,
				texture_size: 512,
			}),
		};
		statusChange = statusChange.bind(this);

		this.test = React.createRef();
	}
	updateUpload = (totalNum, currentNum, total, uploaded) => {
		const { data } = this.state;
		this.setState({ data: data.set("byteUploaded", uploaded).set("totalByte", total).set("totalFileNum", totalNum).set("currentFileNum", currentNum) });
	};
	handleSubmitThree = async () => {
		const { data } = this.state;
		const that = this;

		const fileNum = data.get("files").length;
		let currentNum = 0;
		const fileArray = data.get("files");
		for await (const file of fileArray) {

			const fileReader = new FileReader();
			fileReader.onload = function () {

				window.setTimeout(() => {
					convertPLY(fileReader.result, 1, 1);

				}, 1);
			}
			function convertPLY(plyBuffer, compressionLevel, alphaRemovalThreshold) {
				const plyParser = new GaussianSplats3D.PlyParser(plyBuffer);
				const splatBuffer = plyParser.parseToSplatBuffer(compressionLevel, alphaRemovalThreshold);
				new GaussianSplats3D.SplatLoader(splatBuffer).downloadFile(file.name.replace(".ply", ".ksplat"));
			}
			fileReader.readAsArrayBuffer(file);



		}


	};
	handleSubmit = async () => {
		const { data } = this.state;
		const that = this;

		const fileNum = data.get("files").length;
		let currentNum = 0;
		const fileArray = data.get("files");
		for await (const file of fileArray) {
			const scene = new SPLAT.Scene();
			await SPLAT.PLYLoader.LoadFromFileAsync(file, scene, (progress) => {

			});


			scene.saveToFile(file.name.replace(".ply", ".splat"));

		}


	};

	onValueChanged = (name, value) => {
		const { data } = this.state;

		this.setState({ data: data.set(name, value) });
	};
	async componentDidMount() {
		const { data } = this.state;
		function decodeJwtResponse(token) {
			var base64Url = token.split(".")[1];
			var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
			var jsonPayload = decodeURIComponent(
				atob(base64)
					.split("")
					.map(function (c) {
						return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join("")
			);
			return JSON.parse(jsonPayload);
		}
		this.setState({ data: data.set("email", decodeJwtResponse(this.props.cognito.user.signInUserSession.idToken.jwtToken).email) });
		// console.log(this.props.cognito.user.signInUserSession.idToken.jwtToken)
		// console.log(decodeJwtResponse(this.props.cognito.user.signInUserSession.idToken.jwtToken).email)
	}
	render() {
		const { data } = this.state;
		const totalByte = data.get("totalByte");
		const byteUploaded = data.get("byteUploaded");
		const totalFileNum = data.get("totalFileNum");
		const currentFileNum = data.get("currentFileNum");

		return (
			<Fragment>
				<HeaderComponent signOut={this.props.cognito.signOut} setStage={this.props.setStage} stage={this.props.stage}></HeaderComponent>
				<div className="main_contents">
					<Container fluid>
						<Row>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								Ply파일을 Splat파일로 변환합니다.
							</div>

							{/* <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                3DPresso 테스트를 위해서 잠시 닫아둡니다.
                            </div> */}
							<div className="col-md-12" id="time_c1">
								<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
									<Form className="col-md-6">


										<FormGroup row>
											<Label for="exampleFile" sm={2}>
												File
											</Label>
											<Col sm={10}>
												<Input
													id="exampleFile"
													multiple={true}
													name="file"
													type="file"
													onChange={(e) => {
														const files = e.target.files;
														this.setState({ data: data.set("files", files) });
													}}
												/>
												<FormText>파일을 올려주세요</FormText>
											</Col>
										</FormGroup>

									</Form>
								</div>
							</div>

							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<Button color="primary" onClick={this.handleSubmitThree}>
									변환(three.js)
								</Button>
							</div>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<Button color="primary" onClick={this.handleSubmit}>
									변환(gsplat.js)
								</Button>
							</div>
						</Row>
					</Container>
				</div>
			</Fragment>
		);
	}
}

export default PlyToSplat;

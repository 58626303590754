import React, { Component, Fragment } from 'react';

import Immutable from 'immutable';

import { Map, List, Record } from 'immutable';


import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import AllPage from './pages/AllPage'
import RLGSViewer from './pages/RLGSViewer';

import PlyToSplat from './pages/PlyToSplat';
import SampleUpload from './pages/SampleUpload';
import KSplatViewer from './pages/KSplatViewer';

class MainComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: Map({
                contact: List()
            })


        }
    }
    componentDidMount() {

    }

    render() {

        return (
            <Fragment>
                <Router>
                    <div>
                        <Switch>
                            <Route exact path="/" render={() => <AllPage cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />\

                            <Route exact path="/plytosplat" render={() => <PlyToSplat cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />\

                            <Route exact path="/v" render={() => <RLGSViewer cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />\

                            <Route exact path="/kv" render={() => <KSplatViewer cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />\


                            <Route exact path="/sampleupload" render={() => <SampleUpload cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />\



                        </Switch>
                    </div>
                </Router>
            </Fragment >
        );
    }
}

export default MainComponent;

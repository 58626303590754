import React, { Component, Fragment } from "react";

import Immutable from "immutable";

import { Map, List, Record } from "immutable";

import axios from "axios";

import { Table, Container, Row, InputGroup, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup, FormGroup, Label, Input } from "reactstrap";
import moment from "moment";
import HeaderComponent from "../header/HeaderComponent";


import $ from "jquery";
import "datatables.net";

const { Base64 } = require("js-base64");

function statusChange(type) {
	const { data } = this.state;
	this.setState({ data: data.set("currentSlamState", type) });
}

function formatBytes(bytes, decimals = 2) {
	if (!+bytes) return "0 Bytes";

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
function truncateString(str, maxLength) {
	if (str == undefined) {
		return "";
	}
	if (str.length > maxLength) {
		return str.substring(0, maxLength - 3) + "...";
	} else {
		return str;
	}
}
const getStatusColor = (status) => {
	switch (status) {
		case "failed":
			return "red";
		case "completed":
			return "green";
		default:
			return "black";
	}
};
class AllPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: Map({
				showModal: false,
				tableData: [],
				selectedData: undefined,
				adminComments: [],
				requestTesetSetList: [],
				tesetSetList: [],
				selected_engin_type: "all",
				selectedMode: "all",
				clone_engine_type: "all",
				exclude_company_member: false,
				mobilemode: false,
				max_rows: 25,
				selected_priority: "all",
			}),
		};
		statusChange = statusChange.bind(this);
		this.mqttClient = "";
		this.test = React.createRef();
		this.modalRef = React.createRef();
		window.that = this;
	}


	downloadFile = async (seq, ext) => {
		try {
			const s3SignedUrlData = await axios({
				method: "GET",
				url: `${this.props.urls[this.props.stage]}/admin/download`,
				params: {
					seq: seq,
					result_extension: ext,
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
			const url = s3SignedUrlData.data.url;
			console.log(url);
			window.location = url;
		} catch (e) {
			alert("권한이 없습니다.");
			console.log("e");
		}
		//        return url;
	};
	downloadImageDataFile = async (seq) => {
		try {
			const s3SignedUrlData = await axios({
				method: "GET",
				url: `${this.props.urls[this.props.stage]}/admin/download`,
				params: {
					type: "image_data",
					seq: seq,
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
			const url = s3SignedUrlData.data.url;

			console.log(url);
			window.location = url;
		} catch (e) {
			alert("권한이 없습니다.");
			console.log("e");
		}
	};
	downloadLogDataFile = async (seq) => {
		try {
			const s3SignedUrlData = await axios({
				method: "GET",
				url: `${this.props.urls[this.props.stage]}/admin/download`,
				params: {
					type: "log",
					seq: seq,
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
			const url = s3SignedUrlData.data.url;

			console.log(url);
			window.location = url;
		} catch (e) {
			alert("권한이 없습니다.");
			console.log("e");
		}
	};
	downloadDataFile = async (seq) => {
		try {
			const s3SignedUrlData = await axios({
				method: "GET",
				url: `${this.props.urls[this.props.stage]}/admin/download`,
				params: {
					type: "data",
					seq: seq,
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
			const url = s3SignedUrlData.data.url;

			console.log(url);
			window.location = url;
		} catch (e) {
			alert("권한이 없습니다.");
			console.log("e");
		}
	};
	downloadOrgFile = async (seq) => {
		try {
			const s3SignedUrlData = await axios({
				method: "GET",
				url: `${this.props.urls[this.props.stage]}/admin/download`,
				params: {
					type: "original",
					seq: seq,
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
			const url = s3SignedUrlData.data.url;

			console.log(url);
			window.location = url;
		} catch (e) {
			alert("권한이 없습니다.");
			console.log("e");
		}
	};
	onValueChanged = (name, value) => {
		const { data } = this.state;

		this.setState({ data: data.set(name, value) });
	};
	updatePosition = async (seq) => {
		const { data } = this.state;
		const posx = data.get("posx");
		const posy = data.get("posy");
		const posz = data.get("posz");
		try {
			await axios({
				method: "Put",
				url: `${process.env.REACT_APP_BACKEND_PATH}/samples`,
				data: {
					seq: seq,
					posx: posx,
					posy: posy,
					posz: posz,
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
		} catch (e) {
			console.log(e);
			return "error";
		}

		alert("업데이트 되었습니다.");
		return "ok";
	};
	updateTarget = async (seq) => {
		const { data } = this.state;
		const targetx = data.get("targetx");
		const targety = data.get("targety");
		const targetz = data.get("targetz");
		try {
			await axios({
				method: "Put",
				url: `${process.env.REACT_APP_BACKEND_PATH}/samples`,
				data: {
					seq: seq,
					targetx: targetx,
					targety: targety,
					targetz: targetz,
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
		}
		catch (e) {
			console.log(e);
			return "error";
		}
		alert("업데이트 되었습니다.");
		return "ok";
	}











	updateRotation = async (seq) => {
		const { data } = this.state;
		const rotx = data.get("rotx");
		const roty = data.get("roty");
		const rotz = data.get("rotz");
		try {
			await axios({
				method: "Put",
				url: `${process.env.REACT_APP_BACKEND_PATH}/samples`,
				data: {
					seq: seq,
					rotx: rotx,
					roty: roty,
					rotz: rotz,
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
		} catch (e) {
			console.log(e);
			return "error";
		}
		alert("업데이트 되었습니다.");
		return "ok";
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}
	getDataTableAjax = () => {
		const { data } = this.state;
		console.log(data.get("selectedMode"));
		let mode = data.get("selectedMode");
		let selected_priority = data.get("selected_priority");
		if (mode == "diverged") {
			mode = "all";
		}
		return {
			url: `${process.env.REACT_APP_BACKEND_PATH}/samples?selected_status=${mode}&selected_engine_type=${data.get("selected_engin_type")}&exclude_company_member=${data.get("exclude_company_member")}`,
			type: "get",
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
			error: function (xhr, error, code) {
				alert("권한이 없습니다.");
			},
		};
	};
	viewGSViewer = async (seq, is_splat = true) => {

		window.open(`./kv?seq=${seq}&is_splat=${is_splat}`, "_blank");

		return;
	};
	viewKsplatViewer = async (seq, is_splat = true) => {

		window.open(`https://ivdemo.rlabsdev.com/kv?seq=${seq}&is_splat=${is_splat}`, "_blank");

		return;
	};
	viewViewer = async (seq, is_splat = true) => {

		window.open(`https://webgl.rlabsdev.com/viewer?seq=${seq}&debug=true`, "_blank");

		return;
	};
	toUpload = async () => {

		window.open(`./sampleupload`, "_blank");

		return;
	};
	copyToKClipboard = (seq) => {
		const textToCopy = `https://ivdemo.rlabsdev.com/kv?seq=${seq}`; // Define the text you want to copy
		navigator.clipboard.writeText(textToCopy)
			.then(() => {
				alert('주소가 클립보드에 복사되었습니다.'); // or handle the success state in a more sophisticated way
			})
			.catch(err => {
				console.error('Error in copying text: ', err); // or handle the error state appropriately
			});
	};
	copyToClipboard = (seq) => {
		const textToCopy = `https://webgl.rlabsdev.com/viewer?seq=${seq}&debug=true`; // Define the text you want to copy
		navigator.clipboard.writeText(textToCopy)
			.then(() => {
				alert('주소가 클립보드에 복사되었습니다.'); // or handle the success state in a more sophisticated way
			})
			.catch(err => {
				console.error('Error in copying text: ', err); // or handle the error state appropriately
			});
	};
	toggleModal = (item) => {
		const { data } = this.state;
		this.setState({ data: data.set("showModal", !data.get("showModal")) });
	};
	doShowModal = async (_selectedData) => {
		const { data } = this.state;
		const selectedData = JSON.parse(Base64.decode(_selectedData));

		this.setState({
			data: data.set("showModal", true).set("selectedData", selectedData)
				.set("rotx", selectedData.rotx)
				.set("roty", selectedData.roty)
				.set("rotz", selectedData.rotz)
				.set("targetx", selectedData.targetx)
				.set("targety", selectedData.targety)
				.set("targetz", selectedData.targetz)
				.set("posx", selectedData.posx)
				.set("posy", selectedData.posy)
				.set("posz", selectedData.posz)


		});
	};
	closeModal = () => {
		const { data } = this.state;
		this.setState({ data: data.set("showModal", false) });
	};
	handleClickOutside = (event) => {
		if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
			this.closeModal();
		}
	};
	getColumnSetting = () => {
		const { data } = this.state;
		const thumbnailWidth = 75;
		const thumbnailHeight = 75;
		const engineType = data.get("selected_engin_type");
		const that = this;

		let recoPublicURL = this.props.stage == "prod" ? "https://public.metarecon.ai" : "https://stag.recopublic.rlabsdev.com";

		let idx = { title: "idx", data: "idx", searchable: true, className: "dt-center", width: "2%" };
		let seq = { title: "seq", data: "seq", searchable: true, className: "dt-center", width: "5%" };
		let org_file_name = { title: "org_file_name", data: "org_file_name", searchable: true, className: "dt-center", width: "10%" };
		let title = { title: "title", data: "title", searchable: true, className: "dt-center", width: "10%" };
		let user_id = { title: "생성자", data: "user_id", searchable: true, className: "dt-center", width: "10%" };
		let edit_view = {
			title: "수정",
			data: "seq",
			className: "dt-center",
			render: function (data, type, row, meta) {
				if (row["status"] != "completed") {
					return "처리 중"
				} else {

					return `<button class="btn btn-info btn-sm" onclick="window.that.viewGSViewer('${row.seq}',true)" >수정</button>`

				}
			},
		};
		let result_view = {
			title: "보기(webgl)",
			data: "seq",
			className: "dt-center",
			render: function (data, type, row, meta) {
				if (row["status"] != "completed") {
					return "처리 중"
				} else {

					return `<button class="btn btn-info btn-sm" onclick="window.that.viewViewer('${row.seq}',true)" >webgl</button>`

				}
			},
		};
		let result_kview = {
			title: "보기(ksplat)",
			data: "seq",
			className: "dt-center",
			render: function (data, type, row, meta) {
				if (row["status"] != "completed") {
					return "처리 중"
				} else {

					return `<button class="btn btn-info btn-sm" onclick="window.that.viewKsplatViewer('${row.seq}',true)" >ksplat</button>`

				}
			},
		};
		let result_copy = {
			title: "주소복사(webgl)",
			data: "seq",
			className: "dt-center",
			render: function (data, type, row, meta) {
				if (row["status"] != "completed") {
					return "처리 중"
				} else {

					return `<button class="btn btn-info btn-sm" onclick="window.that.copyToClipboard('${row.seq}')" >복사</button>`

				}
			},
		};
		let result_kcopy = {
			title: "주소복사(ksplat)",
			data: "seq",
			className: "dt-center",
			render: function (data, type, row, meta) {
				if (row["status"] != "completed") {
					return "처리 중"
				} else {

					return `<button class="btn btn-info btn-sm" onclick="window.that.copyToKClipboard('${row.seq}')" >ksplat복사</button>`

				}
			},
		};


		let status = {
			title: "상태",
			data: "status",
			className: "dt-center",
			width: "5%",
			render: function (data, type, row, meta) {
				if (data == "failed") {
					return `<a style="color:red; font-weight:900">실패</a>`;
				} else if (data == "completed") {

					return `<a style="color:green; font-weight:bold">완료</a>`;


				} else if (data == "uploading") {
					return `<a style="color:orange; font-weight:bold">업로딩중</a>`;
				}

				else if (data == "processing") {
					return `<a style="color:blue; font-weight:bold">처리중</a>`;
				}
				else {
					return data;
				}
			},
		};
		let created_datetime = {
			title: "생성시간",
			data: "created_datetime",
			className: "dt-center",
			width: "10%",
			render: function (data, type, row, meta) {
				return moment(data).format("YY-MM-DD HH:mm:ss");
			},
		};
		let detail = {
			title: "자세히",
			data: "status",
			className: "dt-center",
			width: "10%",
			render: function (data, type, row, meta) {
				return `<button class="btn btn-info btn-sm" size="sm"  onclick=window.that.doShowModal('${Base64.encode(JSON.stringify(row))}') >자세히</button>`;
			},
		};
		let arr = [];
		arr = [idx, seq, title, status, org_file_name, created_datetime, user_id, edit_view, result_view, result_kview, result_copy, result_kcopy, detail];
		// if (data.get("selectedMode") == "all") {
		// 	arr = [idx, seq, type, status, created_datetime];
		// }

		return arr;
	};
	initializeDatatable = () => {
		const that = this;
		const { data } = this.state;
		if (this.oTable) {
			this.oTable.destroy();
			this.test.current.innerHTML = "";
		}
		this.oTable = $(this.test.current).DataTable({
			order: [[0, "desc"]],
			iDisplayLength: data.get("max_rows"),
			searching: true,
			processing: true,
			responsive: true,
			language: {
				processing: "Loading...",
			},
			serverSide: true,
			ajax: this.getDataTableAjax(),
			columns: this.getColumnSetting(),
			initComplete: function () {
				$(".dataTables_filter input").unbind();
				$(".dataTables_filter input").bind("keyup", function (e) {
					var code = e.keyCode || e.which;
					if (code == 13) {
						that.oTable.search(this.value).draw();
					}
				});
			},
		});
		$(".dataTables_wrapper").css("width", "100%");
		$("#DataTables_Table_0_length").on("change", function (val) {
			that.setState({ data: data.set("max_rows", parseInt($("#DataTables_Table_0_length  option:selected").text())) });
		});
	};
	refreashTable = (event) => {
		this.initializeDatatable();
	};
	async componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
		this.initializeDatatable();
	}
	getModeKor = () => {
		const { data } = this.state;
		switch (data.get("selectedMode")) {
			case "all":
				return "전체 목록";
			case "processing":
				return "진행 목록";
			case "uploaded":
				return "대기 목록";
			case "completed":
				return "완료 목록";
			case "failed":
				return "실패 목록";
			case "canceled":
				return "취소 목록";
			default:
				return "???";
		}
	};

	hasMatchingWord = (sentence, wordArray) => wordArray.some(word => sentence.includes(word));


	render() {
		const { data } = this.state;
		let showModal = data.get("showModal");
		let selectedData = data.get("selectedData");
		let selectedMode = data.get("selectedMode");
		let newComment = data.get("newComment");
		let adminComments = data.get("adminComments");
		let tesetSetList = data.get("tesetSetList");
		let requestTesetSetList = data.get("requestTesetSetList");
		let exclude_company_member = data.get("exclude_company_member");
		let mobilemode = data.get("mobilemode");

		//<TableBox options={options} sizeClass={"col-md-6"}></TableBox>
		return (
			<Fragment>
				{showModal && (
					<div className="modals">
						<div className="modal-contents" style={{ "overflow-y": "scroll", height: 500 }} ref={this.modalRef}>
							<Row>
								<Col mb={1} sm={1}>
									{selectedData.status == "completed" ? (
										<button
											className="btn btn-info btn-sm"
											size="sm"
											onClick={() => {
												this.viewResult(selectedData);
											}}
										>
											보기
										</button>
									) : (
										""
									)}
								</Col>


							</Row>
							<button className="close-btn" onClick={() => this.toggleModal(null)}>
								X
							</button>
							<Col md={12}>
								<table style={{ width: "100%" }}>
									<thead>
										<tr>
											<th style={{ width: "50%" }}>Prop</th>
											<th style={{ width: "50%" }}>Value</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Viewer URL</td>
											<td>{`https://ivdemo.rlabsdev.com/v?seq=${selectedData.seq}&is_splat=true`}</td>
										</tr>
										<tr>
											<td>idx</td>
											<td>{selectedData.idx}</td>
										</tr>
										<tr>
											<td>seq</td>
											<td>{selectedData.seq}</td>
										</tr>
										<tr>
											<td>상태</td>
											<td style={{ color: getStatusColor(selectedData.status), fontWeight: selectedData.status == "failed" ? "bold" : "normal" }}>{selectedData.status == "failed" ? selectedData.status.toUpperCase() : selectedData.status}</td>
										</tr>
										<tr>
											<td><Col md={3}>로테이션</Col>
												<Col md={3}><Input id="rotx" name="rotx" type="text" placeholder="x" value={data.get("rotx")} onChange={(e) => this.onValueChanged("rotx", e.target.value)} /></Col>
												<Col md={3}><Input id="roty" name="roty" type="text" placeholder="y" value={data.get("roty")} onChange={(e) => this.onValueChanged("roty", e.target.value)} /></Col>
												<Col md={3}><Input id="rotz" name="rotz" type="text" placeholder="z" value={data.get("rotz")} onChange={(e) => this.onValueChanged("rotz", e.target.value)} /></Col>
											</td>
											<td><button
												class="btn btn-primary btn-sm"
												onClick={() => {
													this.updateRotation(selectedData.seq);
												}}
											>
												적용
											</button></td>
										</tr>
										<tr>
											<td><Col md={3}>카메라 타겟</Col>
												<Col md={3}><Input id="targetx" name="targetx" type="text" placeholder="x" value={data.get("targetx")} onChange={(e) => this.onValueChanged("targetx", e.target.value)} /></Col>
												<Col md={3}><Input id="targety" name="targety" type="text" placeholder="y" value={data.get("targety")} onChange={(e) => this.onValueChanged("targety", e.target.value)} /></Col>
												<Col md={3}><Input id="targetz" name="targetz" type="text" placeholder="z" value={data.get("targetz")} onChange={(e) => this.onValueChanged("targetz", e.target.value)} /></Col>
											</td>
											<td><button
												class="btn btn-primary btn-sm"
												onClick={() => {
													this.updateTarget(selectedData.seq);
												}}
											>
												적용
											</button></td>
										</tr>
										<tr>
											<td><Col md={3}>Camera Position</Col>
												<Col md={3}><Input id="posx" name="posx" type="text" placeholder="x" value={data.get("posx")} onChange={(e) => this.onValueChanged("posx", e.target.value)} /></Col>
												<Col md={3}><Input id="posy" name="posy" type="text" placeholder="y" value={data.get("posy")} onChange={(e) => this.onValueChanged("posy", e.target.value)} /></Col>
												<Col md={3}><Input id="posz" name="posz" type="text" placeholder="z" value={data.get("posz")} onChange={(e) => this.onValueChanged("posz", e.target.value)} /></Col>
											</td>
											<td><button
												class="btn btn-primary btn-sm"
												onClick={() => {
													this.updatePosition(selectedData.seq);
												}}
											>
												적용
											</button></td>
										</tr>
										<tr>
											<td>유저아이디</td>
											<td>{selectedData.user_id}</td>
										</tr>


										<tr>
											<td>파일명</td>
											<td>{selectedData.org_file_name}</td>
										</tr>

										<tr>
											<td>원본 파일 다운로드</td>
											<td>
												{
													<button
														class="btn btn-primary btn-sm"
														onClick={() => {
															this.downloadOrgFile(selectedData.seq);
														}}
													>
														Download
													</button>
												}
												({formatBytes(selectedData.file_size)})
											</td>
										</tr>
										<tr>
											<td>결과 파일 다운로드</td>
											<td>
												{selectedData.status == "completed" ? (
													<button
														class="btn btn-primary btn-sm"
														size="sm"
														onClick={() => {
															this.downloadFile(selectedData.seq);
														}}
													>
														Download
													</button>
												) : (
													"-"
												)}
												({selectedData.status == "completed" ? formatBytes(selectedData.result_file_size) : "-"})
											</td>
										</tr>


										<tr>
											<td>로그 다운로드</td>
											<td>
												<button
													class="btn btn-primary btn-sm"
													size="sm"
													onClick={() => {
														this.downloadLogDataFile(selectedData.seq);
													}}
												>
													Download
												</button>
											</td>
										</tr>



									</tbody>
								</table>
							</Col>

						</div>
					</div>
				)}
				<HeaderComponent signOut={this.props.cognito.signOut} setStage={this.props.setStage} stage={this.props.stage}></HeaderComponent>
				<div className="main_contents">
					<Container fluid>
						<Row>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<Col md={4}>
									<Col md={4}>

									</Col>
									<Col md={4}>

									</Col>
									<Col md={3}>

									</Col>

								</Col>

								<Col md={4} style={{ alignItems: "center", display: "flex", fontSize: 25, color: "black", fontWeight: 800, justifyContent: "center" }}>
									{this.getModeKor()}
								</Col>

								<Col md={4}>
									<Col md={4}>

									</Col>
									<Col md={4}>
										<ButtonGroup>
											<Button
												color="info"
												onClick={() => {
													this.initializeDatatable();
												}}
											>
												Refresh
											</Button>
										</ButtonGroup>

									</Col>
									<Col md={4}>
										<ButtonGroup>
											<Button
												color="primary"
												onClick={() => {
													this.toUpload();
												}}
											>
												업로드
											</Button>
										</ButtonGroup>
									</Col>
								</Col>
							</div>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<Table bordered style={{ width: "100%" }} innerRef={this.test} />
							</div>
						</Row>
					</Container>
				</div>
			</Fragment>
		);
	}
}

export default AllPage;

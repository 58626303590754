import React, { useEffect, useState, Component, Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import { Table, Container, Row, InputGroupText, InputGroup, ButtonGroup, Col, Button, Form, FormText, FormGroup, Label, Input } from "reactstrap";
import HeaderComponent from "../header/HeaderComponent";
import ThreeViewer from './iv_viewers/ThreeViewer';
import axios from 'axios';


const RLGSViewer = (props) => {
    const { search } = useLocation();
    const [ivprops, setProps] = useState('');
    const [isSplat, setIsSplat] = useState('');
    const [isDebug, setIsDebug] = useState(false);
    const [isInitiatedMode, setIsInitiatedMode] = useState(true);
    const [use_animation, setUseAnimation] = useState(true);
    const [enableDownloadBtn, setEnableDownloadBtn] = useState(false);

    const [seq, setSeq] = useState(undefined);
    const [viewStatus, setViewStatus] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [progress, setProgress] = useState(0);

    let camearaPos = {
        x: 0,
        y: 0,
        z: 0,
    }
    let _is_debug = false;

    useEffect(() => {

        const searchParams = new URLSearchParams(search);
        const SEQ = searchParams.get("seq");
        setSeq(SEQ);
        const is_splat = searchParams.get("is_splat");

        const useAnimation = searchParams.get("use_animation");
        setUseAnimation(useAnimation);
        setIsSplat(is_splat)

        initiate(SEQ, props, is_splat);


    }, []);

    const initiate = async (seq, props, is_splat = true) => {

        try {
            const s3SignedUrlData = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_BACKEND_PATH}/s3/file`,
                params: {
                    seq: seq
                },
                // headers: {
                //     Authorization: props.cognito.user.signInUserSession.idToken.jwtToken
                // },

            });
            const _url = s3SignedUrlData.data.url;


            const url = _url
            const props = {
                url: s3SignedUrlData.data.ksplat_url,
                posx: s3SignedUrlData.data.posx,
                posy: s3SignedUrlData.data.posy,
                posz: s3SignedUrlData.data.posz,
                rotx: s3SignedUrlData.data.rotx,
                roty: s3SignedUrlData.data.roty,
                rotz: s3SignedUrlData.data.rotz,
                rotangle: s3SignedUrlData.data.rotangle,
                targetx: s3SignedUrlData.data.targetx,
                targety: s3SignedUrlData.data.targety,
                targetz: s3SignedUrlData.data.targetz,
                rotalpha: s3SignedUrlData.data.rotalpha,
                rotbeta: s3SignedUrlData.data.rotbeta,
                kposx: s3SignedUrlData.data.kposx,
                kposy: s3SignedUrlData.data.kposy,
                kposz: s3SignedUrlData.data.kposz,
                kupx: s3SignedUrlData.data.kupx,
                kupy: s3SignedUrlData.data.kupy,
                kupz: s3SignedUrlData.data.kupz,
                ktargetx: s3SignedUrlData.data.ktargetx,
                ktargety: s3SignedUrlData.data.ktargety,
                ktargetz: s3SignedUrlData.data.ktargetz
            }
            if (props.rotx != 0 || props.roty != 0 || props.rotz != 0 || props.rotalpha != 0 || props.rotbeta != 0 || props.rotangle != 0) {
                setIsInitiatedMode(true);
            }
            else {
                setIsInitiatedMode(false)
            }
            setProps(props);
            setEnableDownloadBtn(false);
        }
        catch (e) {

            alert("모델이 없거나 권한이 없습니다. ")

        }
    };


    const toggleInitiatedMode = () => {
        setIsInitiatedMode(!isInitiatedMode);
    };


    const handleError = (e) => {
        if (e.detail.type === 'loadfailure') {
            alert("모델 로딩 실패 : 관리자에게 문의해주세요.")
        }
    };
    const updateInfo = (viewer) => {

        const camera = viewer.camera;
        //console.log(camera);
        const control = viewer.controls;
        setViewStatus({
            camerapos: camera.position,
            up: camera.up,
            target: control.target

        });
    }
    const handleProgress = (ev) => {
        if (typeof ev.detail.totalProgress === 'number') setProgress(ev.detail.totalProgress);
    };
    const resetAll = async () => {
        try {
            await axios({
                method: "Put",
                url: `${process.env.REACT_APP_BACKEND_PATH}/samples`,
                data: {
                    seq: seq,
                    rotx: 0,
                    roty: 0,
                    rotz: 0,
                    rotangle: 0,
                    rotalpha: 0,
                    rotbeta: 0,
                    posx: 0,
                    posy: 0,
                    posz: 0,
                    targetx: 0,
                    targety: 0,
                    targetz: 0,
                },
                headers: {
                    Authorization: props.cognito.user.signInUserSession.idToken.jwtToken,
                },
            });
        } catch (e) {
            console.log(e);
            return "error";
        }
        alert("업데이트 되었습니다.");
        return "ok";
    }
    const updateRotation = async () => {

        try {
            await axios({
                method: "Put",
                url: `${process.env.REACT_APP_BACKEND_PATH}/samples`,
                data: {
                    seq: seq,
                    kposx: viewStatus.camerapos?.x.toFixed(3),
                    kposy: viewStatus.camerapos?.y.toFixed(3),
                    kposz: viewStatus.camerapos?.z.toFixed(3),
                    kupx: viewStatus.up?.x.toFixed(3),
                    kupy: viewStatus.up?.y.toFixed(3),
                    kupz: viewStatus.up?.z.toFixed(3),
                    ktargetx: viewStatus.target?.x.toFixed(3),
                    ktargety: viewStatus.target?.y.toFixed(3),
                    ktargetz: viewStatus.target?.z.toFixed(3),
                },
                headers: {
                    Authorization: props.cognito.user.signInUserSession.idToken.jwtToken,
                },
            });
        } catch (e) {
            console.log(e);
            return "error";
        }
        alert("업데이트 되었습니다.");
        return "ok";
    }
    const viewViewer = async () => {

        window.open(`https://webgl.rlabsdev.com/viewer?seq=${seq}&debug=true`, "_blank");

        return;
    };

    const viewKSplatViewer = async () => {

        window.open(`https://ivdemo.rlabsdev.com/kv?seq=${seq}`, "_blank");

        return;
    };
    // console.log("glbSrc:", glbSrc)
    return (
        <Fragment>
            <HeaderComponent signOut={props.cognito.signOut} ></HeaderComponent>
            <div className="main_contents">
                <Container fluid>
                    <Row>

                        <div className="col-md-12" id="time_c1">
                            <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <Form className="col-md-8">


                                    <Row>

                                        <Col sm={2}>
                                            <Input
                                                id="exampleFile"
                                                value={`posx:${viewStatus.camerapos?.x?.toFixed(3)}`}
                                                name="text"
                                                disabled={true}
                                                type="text"
                                                onChange={(e) => {

                                                }}
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <Input
                                                id="exampleFile"
                                                value={`posy:${viewStatus.camerapos?.y?.toFixed(3)}`}
                                                name="text"
                                                disabled={true}
                                                type="text"
                                                onChange={(e) => {

                                                }}
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <Input
                                                id="exampleFile"
                                                value={`posz:${viewStatus.camerapos?.z?.toFixed(3)}`}
                                                name="text"
                                                disabled={true}
                                                type="text"
                                                onChange={(e) => {

                                                }}
                                            />
                                        </Col>

                                        <Col sm={4}>
                                            <Button color="primary" onClick={updateRotation}>
                                                로테이션 설정
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col sm={2}>
                                            <Input
                                                id="exampleFile"
                                                value={`upX:${viewStatus.up?.x?.toFixed(3)}`}
                                                name="text"
                                                disabled={true}
                                                type="text"
                                                onChange={(e) => {

                                                }}
                                            />
                                        </Col>

                                        <Col sm={2}>
                                            <Input
                                                id="exampleFile"
                                                value={`upY:${viewStatus.up?.y?.toFixed(3)}`}
                                                name="text"
                                                disabled={true}
                                                type="text"
                                                onChange={(e) => {

                                                }}
                                            />
                                        </Col>

                                        <Col sm={2}>
                                            <Input
                                                id="exampleFile"
                                                value={`upZ:${viewStatus.up?.z?.toFixed(3)}`}
                                                name="text"
                                                disabled={true}
                                                type="text"
                                                onChange={(e) => {

                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}>
                                            <Input
                                                id="exampleFile"
                                                value={`targetx:${viewStatus.target?.x?.toFixed(3)}`}
                                                name="text"
                                                disabled={true}
                                                type="text"
                                                onChange={(e) => {

                                                }}
                                            />
                                        </Col>

                                        <Col sm={3}>
                                            <Input
                                                id="exampleFile"
                                                value={`targety:${viewStatus.target?.y?.toFixed(3)}`}
                                                name="text"
                                                disabled={true}
                                                type="text"
                                                onChange={(e) => {

                                                }}
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <Input
                                                id="exampleFile"
                                                value={`targetz:${viewStatus.target?.z?.toFixed(3)}`}
                                                name="text"
                                                disabled={true}
                                                type="text"
                                                onChange={(e) => {

                                                }}
                                            />
                                        </Col>

                                    </Row>
                                    <Col>
                                        <Button color="danger" onClick={resetAll}>
                                            리셋
                                        </Button>
                                        <Button color="info" onClick={viewViewer}>
                                            뷰어보기
                                        </Button>
                                        <Button color="info" onClick={viewKSplatViewer}>
                                            뷰어보기(ksplat)
                                        </Button>
                                    </Col>



                                </Form>

                            </div>
                        </div>
                        <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center", "backgroundColor": "black" }}>
                            <ThreeViewer src={ivprops.url} ivprops={ivprops} use_animation={use_animation} is_splat={isSplat} initiatePosition={true} updateInfo={updateInfo} width={"70%"} height={"90%"} />
                        </div>


                    </Row>
                </Container>
            </div>
        </Fragment >
    );
};

export default RLGSViewer;


import {
    useAuthenticator,
    useTheme,
    View,
    Image,
    Text,
    Heading,
    Button,
} from "@aws-amplify/ui-react";
import React from "react";
import logo from "../../../images/ci_icon_square_black_80.png"
import { Auth } from "aws-amplify";
export const services = {
    async handleSignUp(formData) {
        let { username, password, attributes } = formData;
        // custom username
        username = username.toLowerCase();
        attributes.email = username
        return Auth.signUp({
            username: username,
            password: password,
            attributes: attributes
        });
    },
};


export const components = {
    Header() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Image alt="Amplify logo" src={logo} height="200" />
            </View>
        );
    },

    Footer() {
        const { tokens } = useTheme();


        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                    &copy; RECON Labs All Rights Reserved
                </Text>
            </View>
        );
    },

    SignIn: {
        Header() {
            const { tokens } = useTheme();

            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >

                </Heading>
            );
        },
        Footer() {
            const { toResetPassword } = useAuthenticator();

            return (
                <View textAlign="center">
                    <Button
                        fontWeight="normal"
                        onClick={toResetPassword}
                        size="small"
                        variation="link"
                    >
                        Reset Password
                    </Button>
                </View>
            );
        },
    },

    SignUp: {
        Header() {
            const { tokens } = useTheme();

            return (
                <Heading

                >

                </Heading>
            );
        },
        Footer() {
            const { toSignIn } = useAuthenticator();

            return (
                <View textAlign="center">
                    <Button
                        fontWeight="normal"
                        onClick={toSignIn}
                        size="small"
                        variation="link"
                    >
                        Back to Sign In
                    </Button>
                </View>
            );
        },
    },
    ConfirmSignUp: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >

                </Heading>
            );
        }
    },
    SetupTOTP: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >

                </Heading>
            );
        }
    },
    ConfirmSignIn: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >

                </Heading>
            );
        }
    },
    ResetPassword: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >

                </Heading>
            );
        }
    },
    ConfirmResetPassword: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >

                </Heading>
            );
        }
    },
};


export const formFields = {
    signIn: {
        username: {
            isDisabled: true,
            labelHidden: true,
            // placeholder: "Enter your email",
        },
        password: {
            isDisabled: true,
            labelHidden: true,
            // placeholder: "Enter your email",
        },
    },
    signUp: {
        username: {

            placeholder: '이메일',
            order: 1,
        },

        name: {
            // label: "Username:",
            // labelHidden: false,
            placeholder: "이름",
            order: 3,
        },
        password: {
            // labelHidden: false,
            // label: "Password:",
            // placeholder: "암호:",
            isDisabled: true,
            order: 4,
        },
        confirm_password: {
            // labelHidden: false,
            // placeholder: "암호 확인:",
            order: 5,
        },
    },
    forceNewPassword: {
        password: {
            labelHidden: true,
            // placeholder: "Enter your Password:",
        },
    },
    resetPassword: {
        username: {
            labelHidden: true,
            placeholder: "이메일을 입력해주세요",
        },
    },
    confirmResetPassword: {
        confirmation_code: {
            labelHidden: false,
            // placeholder: "Enter your Confirmation Code:",
            label: "New Label",
            isRequired: false,
        },
        confirm_password: {
            labelHidden: false,
            // placeholder: "Enter your Password Please:",
        },
    },
    setupTOTP: {
        QR: {
            totpIssuer: "test issuer",
            totpUsername: "amplify_qr_test_user",
        },
        confirmation_code: {
            labelHidden: false,
            label: "New Label",
            // placeholder: "Enter your Confirmation Code:",
            isRequired: false,
        },
    },
    confirmSignIn: {
        confirmation_code: {
            labelHidden: false,
            label: "New Label",
            // placeholder: "Enter your Confirmation Code:",
            isRequired: false,
        },
    },
};



export const federated = {
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
};


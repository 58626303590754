import React, { useCallback, useEffect, useRef, useState } from "react";
import * as SPLAT from "../../../scripts/gsplat/index.js";
let Props = {

};

const GSplat = ({ ivprops, use_animation, file, updateInfo, initiatePosition, width = "100%", height = "100%", is_splat = true, }) => {
    const wrapperRef = useRef(null);
    const canvasRef = useRef(null);
    const sceneRef = useRef(null);
    const cameraRef = useRef(null);
    const rendererRef = useRef(null);
    const controlsRef = useRef(null);
    const src = ivprops.url;


    const [progress, setProgress] = useState(false);

    const loadScene = useCallback(async (src, is_splat, ivprops, use_animation, initiatePosition) => {

        const target = [ivprops.targetx, ivprops.targety, ivprops.targetz];
        if (file != undefined) {
            await SPLAT.Loader.LoadFromFileAsync(file, sceneRef.current, (progress) => setProgress(progress));
        }
        else {
            if (is_splat == "false") {
                await SPLAT.PLYLoader.LoadAsync(src, sceneRef.current, (progress) => setProgress(progress));
            }
            else {
                await SPLAT.Loader.LoadAsync(src, sceneRef.current, (progress) => setProgress(progress));
            }
        }
        setProgress(false);

        let startTime = Date.now();

        let xFactor = 0.01;
        let yFactor = 0.001;

        controlsRef.current.setAnimationLogic({
            initialDistance: 4,
            animationLogicFunction: function (control) {
                const currentTime = Date.now();
                const deltaTime = (currentTime - startTime) / 1000;
                if (yFactor != 0) {
                    control.desiredBeta += yFactor * Math.sin((2 * Math.PI * deltaTime) / 100);
                }
                control.desiredAlpha += xFactor;
            },
            animationLogicInterval: 100,
            shouldPauseAnimationOnInput: true,
            pausePeriodOnInput: 3000,
        });
        if (use_animation && use_animation != "false") {
            controlsRef.current.startAnimation();
        }
        const frame = () => {
            if (sceneRef.current !== null) {
                controlsRef.current.update();
                if (rendererRef.current) {
                    rendererRef.current.render(sceneRef.current, cameraRef.current);
                }
                if (updateInfo != undefined) {
                    updateInfo({
                        target: controlsRef.current.getDesiredTarget(),
                        camerapos: cameraRef.current.position,
                        desiredRT: controlsRef.current.getDesiredRT(),
                        desiredAlpha: controlsRef.current.getDesiredAlpha(),
                        desiredBeta: controlsRef.current.getDesiredBeta(),
                        desiredRadius: controlsRef.current.getDesiredRadius(),
                    })
                }
                requestAnimationFrame(frame);
            }
        };
        const handleResize = () => {
            rendererRef.current.setSize(canvasRef.current?.clientWidth ?? 0, canvasRef.current?.clientHeight ?? 0);
        };
        handleResize();
        window.addEventListener("resize", handleResize);

        if (initiatePosition) {

            function createRotationQuaternion(axis, angle) {
                const halfAngle = angle / 2;
                const sinHalfAngle = Math.sin(halfAngle);
                return new SPLAT.Quaternion(

                    axis.x * sinHalfAngle,
                    axis.y * sinHalfAngle,
                    axis.z * sinHalfAngle,
                    Math.cos(halfAngle),
                );
            }
            if (ivprops != undefined && ivprops.rotx != undefined && ivprops.roty != undefined && ivprops.rotz != undefined) {
                // const direction = new SPLAT.Vector3(0, 0, 0).subtract(cameraRef.current.position).normalize();
                sceneRef.current.rotate(quaternionFromSpherical(ivprops.rotalpha, ivprops.rotbeta, 0));
                const direction = new SPLAT.Vector3(0, 0, 0).subtract(cameraRef.current.position).normalize();
                sceneRef.current.rotate(createRotationQuaternion(direction, - ivprops.rotangle))
                function quaternionFromSpherical(alpha, beta) {
                    // Convert alpha and beta to radians if they are in degrees
                    // alpha = alpha * Math.PI / 180;
                    // beta = beta * Math.PI / 180;qq

                    // Rotation quaternion for alpha around the Y-axis
                    const qAlpha = createRotationQuaternion(new SPLAT.Vector3(0, 1, 0), alpha);

                    // Rotation quaternion for beta around the X-axis
                    const qBeta = createRotationQuaternion(new SPLAT.Vector3(1, 0, 0), beta);

                    // Combine the two rotations
                    return qBeta.multiply(qAlpha);
                }

            }
            if (ivprops != undefined && ivprops.posx != undefined && ivprops.posy != undefined && ivprops.posz != undefined) {
                cameraRef.current.position = new SPLAT.Vector3(ivprops.posx, ivprops.posy, ivprops.posz);
            }
            if (ivprops != undefined && ivprops.targetx != undefined && ivprops.targety != undefined && ivprops.targetz != undefined) {
                controlsRef.current.setCameraTarget(new SPLAT.Vector3(ivprops.targetx, ivprops.targety, ivprops.targetz));
            }
            else {
                controlsRef.current.setCameraTarget(new SPLAT.Vector3(0, 0, 0));

            }
        }
        else {

            cameraRef.current.position = new SPLAT.Vector3(0, 0, 0);
            controlsRef.current.setCameraTarget(new SPLAT.Vector3(0, 0, 0));
        }



        requestAnimationFrame(frame);
    }, []);



    useEffect(() => {
        const scene = new SPLAT.Scene();
        const camera = new SPLAT.Camera();
        const renderer = new SPLAT.WebGLRenderer(canvasRef.current);
        const controls = new SPLAT.FollowCameraTrackControls(camera, canvasRef.current, 0.5, 0.5, 5, true, new SPLAT.Vector3(1, -1.1, -2));

        sceneRef.current = scene;
        cameraRef.current = camera;
        rendererRef.current = renderer;
        controlsRef.current = controls;

        return () => {
            // if (rendererRef.current) {
            //     rendererRef.current.dispose();
            // }
            // rendererRef.current = null;
            // sceneRef.current = null;
            // cameraRef.current = null;
            // controlsRef.current = null;
        };
    }, []);
    useEffect(() => {
        if ((src && is_splat != "")) {
            loadScene(src, is_splat, ivprops, use_animation, initiatePosition);
        }
        return () => {
            // if (rendererRef.current) {
            //     rendererRef.current.dispose();
            // }
            // rendererRef.current = null;
            // sceneRef.current = null;
            // cameraRef.current = null;
            // controlsRef.current = null;
        };
    }, [src]);
    useEffect(() => {
        rendererRef.current = null;
        sceneRef.current = null;
        cameraRef.current = null;
        controlsRef.current = null;
        const scene = new SPLAT.Scene();
        const camera = new SPLAT.Camera();
        const renderer = new SPLAT.WebGLRenderer(canvasRef.current);
        const controls = new SPLAT.FollowCameraTrackControls(camera, canvasRef.current, 0.5, 0.5, 5, true, new SPLAT.Vector3(1, -1.1, -2));

        sceneRef.current = scene;
        cameraRef.current = camera;
        rendererRef.current = renderer;
        controlsRef.current = controls;
        if ((src)) {
            loadScene(src, is_splat, ivprops, use_animation, initiatePosition);
        }
        return () => {
            // if (rendererRef.current) {
            //     rendererRef.current.dispose();
            // }
            // rendererRef.current = null;
            // sceneRef.current = null;
            // cameraRef.current = null;
            // controlsRef.current = null;
        };
    }, [initiatePosition]);


    return (
        <div style={{ position: "relative", width, height }} ref={wrapperRef}>
            <canvas ref={canvasRef} style={{ width: "100%", height: "100%" }}></canvas>
            {progress !== false && <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "white", textAlign: "center" }}>{progress * 100}%</div>}
        </div>
    );
};

export default GSplat;

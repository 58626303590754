import React, { Component, Fragment } from "react";
import { Map, List, Record } from "immutable";
import axios from "axios";
import { Table, Container, Row, Dropdown, DropdownMenu, DropdownItem, InputGroupText, InputGroup, Col, Button, Form, FormText, FormGroup, Label, Input } from "reactstrap";
import HeaderComponent from "../header/HeaderComponent";
function statusChange(type) {
	const { data } = this.state;
	this.setState({ data: data.set("currentSlamState", type) });
}

class SampleUpload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: Map({
				totalByte: 0,
				byteUploaded: 0,

				files: [],
				title: "",
				comment: "",
				email: "",
				totalFileNum: 0,
				currentFileNum: 0,
				parms: "{}",

			}),
		};
		statusChange = statusChange.bind(this);

		this.test = React.createRef();
	}
	updateUpload = (totalNum, currentNum, total, uploaded) => {
		const { data } = this.state;
		this.setState({
			data: data.set("byteUploaded", uploaded).set("totalByte", total).set("totalFileNum", totalNum).set("currentFileNum", currentNum),
		});
	};
	handleSubmit = async () => {
		const { data } = this.state;
		const that = this;
		if (data.get("title") == undefined || data.get("title") == "") {
			alert("title을 입력해주세요");
			return;
		}

		const fileNum = data.get("files").length;
		let currentNum = 0;
		const fileArray = data.get("files");

		let _enggineType = data.get("engine_type");
		if (data.get("includeiv") == "yesiv") {
			let stage = _enggineType.split("-")[1];
			_enggineType = `mriv-${stage}`
		}

		for await (const file of fileArray) {

			const ext = file.name.split(".").pop().toLowerCase();
			if (ext != "mov" && ext != "mp4" && ext != "ply") {
				alert("영상형식만 (mov,mp4) or ply 파일만 올려주세요");
				return
			}
			currentNum++;
			const s3SignedUrlData = await axios({
				method: "GET",
				url: `${process.env.REACT_APP_BACKEND_PATH}/s3`,
				params: {

					filename: file.name,
					file_size: file.size,
					title: data.get("title"),
					comment: data.get("comment"),
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
			console.log(s3SignedUrlData);
			const url = s3SignedUrlData.data.url;



			try {
				const response = await axios.request({
					url: url,
					method: "PUT",
					headers: {
						"Content-Type": "application/octet-stream",
					},
					data: file,

					onUploadProgress: function (progressEvent) {
						if (progressEvent.loaded >= progressEvent.total) {
							that.updateUpload(fileNum, 0, 0, 0);
						} else {
							that.updateUpload(fileNum, currentNum, progressEvent.total, progressEvent.loaded);
						}
					},
				});

			} catch (e) {
				console.log(e);
			}
		}
		alert("업로드를 완료했습니다.");
		that.updateUpload(0, 0, 0, 0);
	};
	pushProfilePhotoToS3 = async (presignedUrl, uploadPhoto) => {
		const myHeaders = new Headers({ "Content-Type": "image/*" });
		const response = await fetch(presignedUrl, {
			method: "PUT",
			headers: myHeaders,
			body: this.file,
		});
	};
	onValueChanged = (name, value) => {
		const { data } = this.state;

		this.setState({ data: data.set(name, value) });
	};
	async componentDidMount() {
		const { data } = this.state;
		function decodeJwtResponse(token) {
			var base64Url = token.split(".")[1];
			var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
			var jsonPayload = decodeURIComponent(
				atob(base64)
					.split("")
					.map(function (c) {
						return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join("")
			);
			return JSON.parse(jsonPayload);
		}
		this.setState({
			data: data.set("email", decodeJwtResponse(this.props.cognito.user.signInUserSession.idToken.jwtToken).email),
		});
		// console.log(this.props.cognito.user.signInUserSession.idToken.jwtToken)
		// console.log(decodeJwtResponse(this.props.cognito.user.signInUserSession.idToken.jwtToken).email)
	}
	render() {
		const { data } = this.state;
		const totalByte = data.get("totalByte");
		const byteUploaded = data.get("byteUploaded");
		const totalFileNum = data.get("totalFileNum");
		const currentFileNum = data.get("currentFileNum");
		return (
			<Fragment>
				<HeaderComponent signOut={this.props.cognito.signOut} setStage={this.props.setStage} stage={this.props.stage}></HeaderComponent>
				<div className="main_contents">
					<Container fluid>
						<Row>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								모델 뷰어로 만들어드립니다.
							</div>
							{/* <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                3DPresso 테스트를 위해서 잠시 닫아둡니다.
                            </div> */}
							<div className="col-md-12" id="time_c1">
								<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
									<Form className="col-md-6">
										<FormGroup row>
											<Label for="exampleEmail" sm={2}>
												Email
											</Label>
											<Col sm={10}>
												<Input disabled={true} id="exampleEmail" name="email" type="email" placeholder="email" value={data.get("email")} onChange={(e) => this.onValueChanged("email", e.target.value)} />
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="exampleFile" sm={2}>
												File
											</Label>
											<Col sm={10}>
												<Input
													id="exampleFile"
													name="file"
													type="file"
													multiple={true}
													onChange={(e) => {
														const files = e.target.files;
														this.setState({ data: data.set("files", files) });
													}}
												/>
												<FormText>영상형식만 (mov,mp4) or 이미지 압축(zip파일) 만 올려주세요</FormText>
											</Col>
										</FormGroup>
										<FormGroup row>
											<Label for="title" sm={2}>
												제목
											</Label>
											<Col sm={10}>
												<Input id="title" name="title" type="text" placeholder="title" value={data.get("title")} onChange={(e) => this.onValueChanged("title", e.target.value)} />
											</Col>
										</FormGroup>
										<FormGroup row>
											<Label for="comment" sm={2}>
												Comment
											</Label>
											<Col sm={10}>
												<Input id="comment" name="comment" type="text" placeholder="comment" value={data.get("comment")} onChange={(e) => this.onValueChanged("comment", e.target.value)} />
											</Col>
										</FormGroup>




									</Form>
								</div>
							</div>
							{totalFileNum != 0 ? (
								<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
									<a style={{ fontWeight: 800, color: "red" }}>{`파일번호:${currentFileNum}/${totalFileNum}  업로드중 : ${byteUploaded != 0 ? parseInt((byteUploaded * 100) / totalByte) + "%" : "-"}`}</a>
								</div>
							) : (
								<div></div>
							)}

							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<Button color="primary" onClick={this.handleSubmit}>
									업로드
								</Button>
							</div>
						</Row>
					</Container>
				</div>
			</Fragment>
		);
	}
}

export default SampleUpload;

/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef } from "react";
import * as GaussianSplats3D from "../../../scripts/ksplat/gaussian-splats-3d.module.js";

const DEFAULT_OPTIONS = {
    cameraUp: [-0.5, 0, -1],
    initialCameraPosition: [-3.15634, -0.16946, -0.51552],
    initialCameraLookAt: [1.52976, 2.27776, 1.65898],
};

const ThreeViewer = ({ ivprops, file, updateInfo, }) => {
    const containerRef = useRef(null);
    const viewerRef = useRef(null);
    const src = ivprops.url
    let cameraOptions = {
        cameraUp: [ivprops.kupx, ivprops.kupy, ivprops.kupz],
        initialCameraPosition: [ivprops.kposx, ivprops.kposy, ivprops.kposz],
        initialCameraLookAt: [ivprops.ktargetx, ivprops.ktargety, ivprops.ktargetz],

    }
    useEffect(() => {
        if (src) {
            const viewer = viewerRef.current ?? new GaussianSplats3D.Viewer({
                ...cameraOptions, sharedMemoryForWorkers: false,
                halfPrecisionCovariancesOnGPU: true,
                rootElement: containerRef.current,
                showControlPlane: true,
            });
            viewerRef.current === null && viewer.init();

            viewer
                .loadFile(src, {


                    is_splat: true,
                })
                .then(() => {
                    viewer.start();
                });

            viewerRef.current = viewer;
            setInterval(() => {
                updateInfo(viewerRef.current)
            }, 200);
        }
        else if (file) {


            const fileReader = new FileReader();
            fileReader.onload = function () {
                const viewer = viewerRef.current ?? new GaussianSplats3D.Viewer({ ...cameraOptions, rootElement: containerRef.current });
                viewerRef.current === null && viewer.init();
                viewer.init();
                const splatBuffer = new GaussianSplats3D.SplatBuffer(fileReader.result);

                viewer.loadSplatBuffersIntoMesh([splatBuffer], [{
                    'halfPrecisionCovariancesOnGPU': true,
                    'is_splat': true,
                    'splatAlphaRemovalThreshold': 1
                }])
                    .then(() => {
                        viewer.start();
                    });
                viewerRef.current = viewer;
            }
            fileReader.readAsArrayBuffer(file);

        }
        return () => {
            if (viewerRef.current) {
                // viewerRef.current.destroy();
                // let renderer = viewerRef.current.renderer as THREE.WebGLRenderer;
                // viewerRef.current.splatMesh = null;
                // viewerRef.current.splatRenderTarget = undefined;
                // renderer?.renderLists.dispose();
                // renderer?.dispose();
                // viewerRef.current = null;
            }
        };
    }, [src]);

    return <div ref={containerRef} style={{ width: "100%", height: "100vh" }}></div>;
};

export default ThreeViewer;

// const compressionLevel = 1;
// const splatAlphaRemovalThreshold = 5; // out of 255
// const plyLoader = new GaussianSplats3D.PlyLoader();

// plyLoader.loadFromURL(src, compressionLevel, splatAlphaRemovalThreshold).then((splatBuffer) => {
// viewer
//   .loadSplatBuffer(splatBuffer, {
//     halfPrecisionCovariancesOnGPU: true,
//   })
//   .then(() => {
//     viewer.start();
//   });
// });
